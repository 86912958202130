<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<!-- <b-button
					v-if="hasAccess('NewCustomer')"
					class="float-right"
					variant="success"
					@click="$router.push({ name: 'NewCustomer'});">
					{{ translate('create') }}
				</b-button> -->
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-md">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:default-params="{role:'customer,affiliate'}"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div class="col-md">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>
							<select
								id="countryName"
								v-model="filters.country"
								:disabled="staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div class="col-md">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option
									:value=" '' || undefined ">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md">
						<div
							class="form-group">
							<label
								for="type"
								class="label">{{ translate('type') }}</label>
							<select
								id="type"
								v-model="filters.type"
								name="type"
								class="form-control">
								<option
									:value=" '' || undefined ">
									{{ translate('all') }}
								</option>
								<option
									v-for="role in customerRoles"
									:key="role"
									:value="role">
									{{ translate(role) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0 pointer">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('id')">
							{{ translate('user_id') }}
							<sort field="id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('type')">
							{{ translate('type') }}
							<sort field="type" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('first_name')">
							{{ translate('name') }}
							<sort field="first_name" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('email')">
							{{ translate('email') }}
							<sort field="email" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('country')">
							{{ translate('country') }}
							<sort field="country" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<th class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span class="badge badge-primary">{{ item.id }}</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.type) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="getCountry(item.attributes.country)"
								:src="item.attributes.country ? require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`) : ''"
								:alt="getCountry(item.attributes.country)">
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="text-center align-middle">
							<template
								v-if="showButtons(item)">
								<b-button
									v-if="hasAccess('UpdateCustomerPassword', { customerId: item.id })"
									v-b-tooltip.hover
									:title="translate('update_thing', {name: translate('password')})"
									variant="primary"
									class="bg-primary-alt mr-1"
									@click="$router.push({ name: 'UpdateCustomerPassword', params: { customerId: item.id, username: item.attributes.username } });">
									<i
										class="fa fa-key"
										aria-hidden="true" />
								</b-button>
								<b-button
									v-if="hasAccess('UpdateCustomer', { customerId: item.id })"
									v-b-tooltip.hover
									:title="translate('update_thing', {name: translate('customer')})"
									variant="primary"
									class="bg-primary-alt"
									@click="$router.push({ name: 'UpdateCustomer', params: { customerId: item.id, username: item.attributes.username } });">
									<i
										class="fa fa-user-edit"
										aria-hidden="true" />
								</b-button>
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<isLoading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>

import { PAGINATION } from '@/settings/RequestReply';
import { CUSTOMERS_REPORT_FILTERS as statuses } from '@/settings/Statuses';
import { customerRoles } from '@/settings/Roles';
import {
	Countries, Profile, Users, Tooltip, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import Customer from '@/util/User';
import Country from '@/util/Country';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Customers',
	messages: [Countries, Profile, Users, Tooltip, Grids],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams],
	data() {
		return {
			customer: new Customer(),
			statuses,
			countryData: new Country(),
			endpoint,
			customerRoles,
		};
	},
	computed: {
		loading() {
			return !!this.customer.data.loading;
		},
		errors() {
			return this.customer.data.errors;
		},
		pagination() {
			return this.customer.data.pagination;
		},
		data() {
			try {
				const { data } = this.customer.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		staffCountry() {
			return this.$user.details().country !== undefined;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	watch: {
		'filters.status': function (value) { // eslint-disable-line func-names
			this.filters.status = typeof value === 'undefined' || value === '' ? undefined : value;
		},
		'filters.countryId': function (value) { // eslint-disable-line func-names
			this.filters.countryId = typeof value === 'undefined' || value === '' ? undefined : value;
		},
		'filters.type': function (value) { // eslint-disable-line func-names
			this.filters.type = typeof value === 'undefined' || value === '' ? undefined : value;
		},
	},
	mounted() {
		this.countryData.getCountries();
		this.customer.getCustomers();
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.customer.getUsers(options);
		},
		showButtons(item) {
			return this.hasAccess('UpdateCustomerPassword', { customerId: item.id }) || this.hasAccess('UpdateCustomer', { customerId: item.id });
		},
		getCountry(item) {
			try {
				return this.translate(item.toLowerCase());
			} catch (error) {
				return '';
			}
		},
	},
};
</script>
